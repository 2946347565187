import React from 'react';

import {useNavigate} from "react-router-dom";
import {AppBar, Box, Button, Card, IconButton, Paper, Stack, Toolbar, Typography} from "@mui/material";

type MyAppBarProps = {
    login: () => void
    timer: () => void
}
const MyAppBar = ({ login, timer }: MyAppBarProps) => {
    return (<Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    mini.CONF - Countdown App
                </Typography>
                <Button color="inherit" onClick={timer}>timer</Button>
                <Button color="inherit" onClick={login}>login</Button>
            </Toolbar>
        </AppBar>
    </Box>)
}
export default function Home() {
    const navigate = useNavigate()

    const goToAdmin = () => {
        navigate("/admin")
    }

    const goToCountdown = () => {
        navigate("/countdown")
    }

    return (
      <>
          <MyAppBar login={goToAdmin} timer={goToCountdown}></MyAppBar>
          <Stack p={2}>
            <Paper variant={"outlined"}>
              <Card sx={{ padding: 2 }}>This is the countdown app - that's all I have to say about it...</Card>
            </Paper>
          </Stack>
      </>
    )
}


