import React from 'react';
import {Timeslot, TimeslotApi} from "../../types";
import {subscribe} from "../../config/firebase-miniconf";

let impl = {
  subscribe(cb: (timeslots: Array<Timeslot>) => void) { return subscribe(cb) }
} as TimeslotApi

const TimeslotContext = React.createContext(impl)

export {
  TimeslotContext
}
