import React, {useEffect, useState} from 'react';
import './style.css'
import {Timeslot} from "../../types";

type Props = {
  readonly now: number
  readonly timeslot: Timeslot
  readonly danger?: number
  readonly warning?: number
}

export default function MiniconfTalkCountdown({now, timeslot, warning = 5, danger = 2}: Props) {

  const [remaining, setRemaining] = useState(null as string | null);
  const [context, setContext] = useState("" as string)

  const start = Date.parse(new Date().toISOString().substring(0, 11) + timeslot.start)
  const end = Date.parse(new Date().toISOString().substring(0, 11) + timeslot.end)

  useEffect(() => {

    let date = ""
    if (now > start && now < end) {
      date = new Date(end).toISOString()
    }

    if (date.length > 0) {
      const millisLeft = Date.parse(date) - now

      const x = new Date(millisLeft)
      if (millisLeft / 1000 / 60 < warning) {
        setContext("warning")
      }
      if (millisLeft / 1000 / 60 < danger) {
        setContext("danger")
      }
      if (millisLeft / 1000 / 60 > warning) {
        setContext("")
      }
      if (millisLeft >= 0) {
        setRemaining(x.toISOString().substring(11, 19))
      } else {
        setRemaining(null)
      }
    } else {
      setRemaining(null)
    }

  }, [start, end, warning, danger, now, setRemaining])

  if (remaining === null) {
    return (<React.Fragment/>)
  }

  return (<div className={context + " miniconf-talk-countdown"}>{remaining}</div>);
}
