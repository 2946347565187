import React, {useContext, useEffect, useState} from 'react';
import MiniconfTalkCountdown from "../components/miniconf-talk-countdown";
import MiniconfTalkTimeOver from "../components/miniconf-talk-time-over";
import {Timeslot} from "../types";
import {TimeslotContext} from "../services/timeslots";

export default function Countdown() {

    const service = useContext(TimeslotContext)
    const [timeslots, setTimeslots] = useState([] as Array<Timeslot>)
    const [timeslot, setTimeslot] = useState(undefined as Timeslot | undefined)
    const [now, setNow] = useState(Date.now)

    useEffect(() => {
        return service.subscribe((timeslots) => {
            setTimeslots(timeslots)
        })
    }, [service, setTimeslots])

    useEffect(() => {
        for (let i=0; i<timeslots.length;i++) {
            const start = Date.parse(new Date().toISOString().substring(0, 11) + timeslots[i].start)
            const end = Date.parse(new Date().toISOString().substring(0, 11) + timeslots[i].end)
            const now = Date.now()

            if (now > start && now < end) {
                setTimeslot(timeslots[i])
                return
            }
        }

        //if we fall through, it's time!!
        setTimeslot(undefined)
    }, [now, timeslots, setTimeslot])


    useEffect(() => {
        const handle = setInterval(() => {
            setNow(Date.now())
        }, 100)
        return () => {
            clearInterval(handle)
        }
    }, [setNow])

    return (
      <React.Fragment>
          {timeslot && <MiniconfTalkCountdown timeslot={timeslot} now={now}/>}
          {!timeslot && <MiniconfTalkTimeOver visible={true}/>}
      </React.Fragment>
    )
}
