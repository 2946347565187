import React from 'react';
import {Outlet, Route, Routes} from "react-router-dom";
import Home from "./routes/home";
import Login from "./routes/login";
import PrivateRoute from "./routes/private-route";
import Admin from "./routes/admin";
import Countdown from "./routes/countdown";
import {AppBar, Box, Button, IconButton, Toolbar, Typography} from "@mui/material";

import MenuIcon from '@mui/icons-material/Menu';

function App() {

  return (
    <>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/countdown" element={<Countdown/>} />
        <Route path="/admin" element={<PrivateRoute element={<Admin/>}/>}/>
        <Route path="/login" element={<Login/>}/>
      </Routes>
      <Outlet/>
    </>
  );
}

export default App;
