import {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../config/firebase-miniconf";

type Props = {
  element: JSX.Element
}

export default function PrivateRoute({element}: Props) {
  const navigate = useNavigate()
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
      if (!loading && user?.uid === undefined) {
        navigate("/login")
      }
    }, [user, loading, navigate])

  if (loading) {
    return (<div>Loading...</div>)
  }

  return (element)
}
