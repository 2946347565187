import React, {ChangeEvent, useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {login} from "../config/firebase-miniconf"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";

export default function Login() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate()

  const onClick = useCallback(() => {
    login(email, password).then((e) => {
      navigate("/admin")
    })
  }, [navigate, email, password])

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.name === "email") {
      setEmail(e.target.value)
    }
    if (e.target.name === "password") {
      setPassword(e.target.value)
    }
  }

  return (<React.Fragment>
    <div>login</div>
    <Dialog open={true}>
      <DialogTitle>Please login</DialogTitle>
      <DialogContent>
        <Stack>
          <Typography>Email</Typography>
          <TextField name="email" value={email} onChange={onChange}/><br/>
          <Typography>Pasword</Typography>
          <TextField name="password" value={password} type="password" onChange={onChange}/><br/>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClick}>login</Button>
      </DialogActions>
    </Dialog>
  </React.Fragment>);
}
