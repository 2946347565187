// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {addDoc, setDoc, collection, doc, deleteDoc, getFirestore, onSnapshot} from "firebase/firestore";
import {Timeslot} from "../types";

import {getAuth, signInWithEmailAndPassword,} from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC-uOidJUvxY83CNHWBfwd2v7EVM3KWDxo",
  authDomain: "miniconf-8e21a.firebaseapp.com",
  projectId: "miniconf",
  appId: "1:188403056174:web:a981f51a8b805822cea9bf"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app)
const rootDoc = doc(db, process.env.NODE_ENV, "timeslots")


// TODO: this should be done better??
const subscribe = (updateFn: (timeslots: Array<Timeslot>) => void) => {
  const timeslots = new Map<string, Timeslot>()

  const today = new Date().toISOString().substring(0, 10)

  // it's either 'production' or 'development'
  // staging therefore also uses 'production' (for now)
  console.log("process.env", process.env)

  // every time there is an update, we alter the 'timeslots' entries
  // when done we publish these latest changes in one go
  return onSnapshot(collection(rootDoc, today), (doc) => {
    console.log(doc)

    doc.docChanges().filter(it => {
      return it.type === "removed"
    }).forEach(value => {
      console.log('deleted', value.doc.id)
      timeslots.delete(value.doc.id)
    })

    doc.docChanges().filter(it => {
      return it.type !== "removed"
    }).forEach(value => {
      timeslots.set(value.doc.id, value.doc.data() as Timeslot)
    })

    updateFn(Array.from(timeslots.values()) as Array<Timeslot>)
  })
}

const add = async (day: string, timeslot: Timeslot) => {
  const collectionRef = collection(rootDoc, day)
  await addDoc(collectionRef, timeslot);
}

const save = async (day: string, id: string, timeslot: Timeslot) => {
  const collectionRef = collection(rootDoc, day)
  const docRef = doc(collectionRef, id)
  await setDoc(docRef, timeslot);
}

const remove = async (day: string, id: string) => {
  const docRef = doc(collection(rootDoc, day), id)
  await deleteDoc(docRef)
}

const login = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password)
}

export {
  rootDoc,
  auth,
  subscribe,
  add,
  save,
  remove,
  login
}
