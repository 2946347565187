import React, {ChangeEvent, useCallback} from "react";
import {add, save, remove, rootDoc} from "../config/firebase-miniconf"
import {collection, getDocs} from "firebase/firestore";
import {useEffect, useState} from "react";
import {Timeslot} from "../types";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl, Grid,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {DatePicker, DesktopDatePicker} from "@mui/x-date-pickers";

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {Moment} from "moment";

type Props = {
    timeslot: Timeslot
    onDelete: () => void
    onEdit: () => void
}

const TimeslotEntry = ({timeslot, onDelete, onEdit}: Props) => {

    return (<>
        <Grid container>
            <Grid item height={50} xs={4}>
                <Typography variant={"h5"}>{timeslot.start} to {timeslot.end}</Typography>
            </Grid>

            <Grid item height={50} xs={2}>
                <Button variant={"outlined"} onClick={() => {
                    onEdit()
                }}>edit</Button>
            </Grid>

            <Grid item height={50} xs={2}>
                <Button variant={"outlined"} onClick={() => {
                    onDelete()
                }}>delete</Button>
            </Grid>
        </Grid>
    </>)
}

const format = (date: Date): string => {
    const hhmm = date.toISOString().substring(11, 16)
    return `${hhmm}:00`
}

type Id = {
    id: string
}

type TimeslotWithId = Timeslot & Id

const clearTimeslot = {start: "", end: "", id: "new"} as TimeslotWithId

export default function Admin() {
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [docs, setDocs] = useState([] as Array<TimeslotWithId>)
    const [timeslot, setTimeslot] = useState(clearTimeslot as TimeslotWithId)
    const [update, setUpdate] = useState(Date.now())

    useEffect(() => {
        const dateString = selectedDate.toISOString().substring(0, 10) as string
        getDocs(collection(rootDoc, dateString)).then((snapshot) => {
            const data: Array<TimeslotWithId> = snapshot.docs
                .map((doc) => {
                    const data = doc.data() as Timeslot
                    return {id: doc.id, start: data.start, end: data.end}
                })
                .map((doc) => {
                    return doc as TimeslotWithId
                })
            setDocs(data)
        })
    }, [setDocs, selectedDate, update])

    const onSelectNewDate = useCallback((value: Moment | null, raw) => {
        if (value?.isValid()) {
            setSelectedDate(value?.toDate())
        }
    }, [selectedDate])

    // generate a new timeslot
    const newTimeslot = () => {
        const offset = -new Date().getTimezoneOffset()
        setTimeslot({
            start: format(new Date(Date.now() + offset * 60000)),
            end: format(new Date(Date.now() + 1000 * 60 * (offset + 15))),
            id: "new"
        } as TimeslotWithId)
    }

    const addTimeslot = () => {
        const dateString = selectedDate.toISOString().substring(0, 10) as string
        add(dateString, timeslot).then(() => {
            setUpdate(Date.now())
        })
    }

    const saveTimeslot = () => {
        const dateString = selectedDate.toISOString().substring(0, 10) as string
        save(dateString, timeslot.id, timeslot).then(() => {
            setUpdate(Date.now())
        })
    }

    const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.target.name === "start") {
            setTimeslot({...timeslot, start: e.target.value})
        }
        if (e.target.name === "end") {
            setTimeslot({...timeslot, end: e.target.value})
        }
    }

    const onKeyUp = (e: any) => {
        console.log(e)
        if (e.key === 'ArrowUp') {

        }
        if (e.key === 'ArrowDown') {

        }
    }

    const onDelete = useCallback(
        async (id: string) => {
            const dateString = selectedDate.toISOString().substring(0, 10) as string
            await remove(dateString, id)
            setUpdate(Date.now)
        },
        [setUpdate, selectedDate]);

    return (<React.Fragment>
        <Stack spacing={8} mx={4} p={2}>
            <Typography variant={"h5"}>mini.CONF date</Typography>
        </Stack>
        <Stack spacing={8} mx={4} maxWidth={300}>
            <FormControl>
                <LocalizationProvider dateAdapter={AdapterMoment}>

                    <DesktopDatePicker
                        label="Selected Date"
                        inputFormat="MM/DD/YYYY"
                        value={selectedDate}
                        onChange={onSelectNewDate}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </FormControl>
        </Stack>
        <br/>

        <Stack spacing={8} mx={4}>
            <Typography variant={"h5"}>Timeslots</Typography>
        </Stack>
        <br/>

        <Stack spacing={4} mx={4}>
            <Box>
                {docs.map(doc => (
                    <TimeslotEntry key={doc.id} timeslot={doc} onEdit={() => setTimeslot(doc as TimeslotWithId)}
                                   onDelete={() => onDelete(doc.id)}/>))}
                <br/>
            </Box>
            <Box>
                <Button variant={"outlined"} onClick={newTimeslot}>add</Button>
            </Box>
        </Stack>

        <Dialog open={timeslot.start !== ""} onClose={() => setTimeslot(clearTimeslot)} fullWidth>
            {timeslot.id !== "new" && <DialogTitle>Edit timeslot</DialogTitle>}
            {timeslot.id === "new" && <DialogTitle>Add timeslot</DialogTitle>}
            <DialogContent>
                <Stack spacing={3}>
                    <Typography variant={"h5"}>Start</Typography>
                    <TextField name="start" value={timeslot.start} onChange={onChange}/><br/>

                    <Typography variant={"h5"}>End</Typography>
                    <TextField name="end" value={timeslot.end} onChange={onChange} onKeyUp={onKeyUp}/><br/>
                </Stack>
            </DialogContent>
            <DialogActions>
                {timeslot.id === "new" && <Button onClick={addTimeslot}>add</Button>}
                {timeslot.id !== "new" && <Button onClick={saveTimeslot}>save</Button>}
                <Button onClick={() => setTimeslot(clearTimeslot)}>close</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>)
}
