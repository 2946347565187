import React from 'react';
import './style.css'

type Props = {
    readonly visible: boolean
}

export default function MiniconfTalkTimeOver({visible}: Props) {
    if (visible) {
        return (<div className="miniconf-talk-time-over">TIME'S UP</div>)
    }
    return (<React.Fragment/>);
}
